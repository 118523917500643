<template>
  <div class="home">
<!--    <elMenu></elMenu>-->
    <img src="../../../../public/images/IPC/lowPowerConsumption/download.jpg" width="100%">
    <div class="solarEnergy_box">
      <div class="solarEnergy_box_1">
        <img src="../../../../public/images/IPC/lowPowerConsumption/2.jpg" width="400px" height="400px">
      </div>
      <div class="solarEnergy_box_2">
        <h1>太阳能低功耗摄像机</h1>
        <p>品牌：维拍</p>
        <p>产品功能：双向语音对讲，自带回音消除,PIR移动侦测唤醒、手机远程主动唤醒</p>
        <p>内存容量：8G 16G 32G</p>
        <p>智能类型：安卓 IOS</p>
        <p>质保年限：1年</p>
        <p>颜色：</p>
      </div>
    </div>
    <div class="center">
      <div class="tile">详情介绍</div>
      <img src="../../../../public/images/IPC/solarEnergy/download.jpg">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../../src/components/elMenu'
import foot from '../../../../src/components/foot'
import navigation from '../../../../src/components/navigation'
export default {
  name: "solarEnergy",
  components: {
    elMenu, foot,navigation
  },
}
</script>

<style scoped>
.solarEnergy_box{
  width: 1280px;
  margin: 10px auto;
  height: 430px;
  border: 1px #ececec solid;
}
.solarEnergy_box_1{
  width: 45%;
  float: left;
  height: 400px;
  text-align: center!important;
}
.solarEnergy_box_2{
  width: 51%;
  float: left;
  height: 400px;
  border-left: 1px solid #ececec;
  padding-left: 25px;
  margin-top: 15px;
}
.center{
  text-align: center;
  margin: auto;
}
.tile{
  font-size: 24px;
  margin: 70px;
}
</style>